<template>
  <div>
    <c-search-box @enter="getList">
      
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-2d"
            defaultEnd="6d"
            label="작업계획기간"
            name="plantDts"
            v-model="searchParam.plantDts"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept type="search" label="정비요청 부서" name="reqDeptCd" v-model="searchParam.reqDeptCd" :isFirstValue="false" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept type="search" label="정비오더 발행부서" name="workDeptCd" v-model="searchParam.workDeptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            codeGroupCd="WO_WORK_PLAN_STEP_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="woWorkPlanStepCd"
            label="진행상태"
            v-model="searchParam.woWorkPlanStepCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-checkbox
            :isFlag="true"
            falseLabel="All"
            label="PM 생성여부"
            name="pmFlag"
            v-model="searchParam.pmFlag"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="정비요청 목록"
      :columns="grid.columns"
      :data="grid.data"
      gridHeight="500px"
      @linkClick="linkClick"
      :isExcelDown="false"
      :isFullScreen="false"
      :columnSetting="false"
      selection="single"
      rowKey="workPlanId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='woWorkPlanStepCd'">
          <q-chip
            :color="getColors(props.row.woWorkPlanStepCd)"
            :class="props.row.woWorkPlanStepCd == 'WWPSC00000' ? 'blinking' : ''"
            text-color="white">
            {{props.row.woWorkPlanStepName}}
          </q-chip>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        plantCd: '',
      }),
    },
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        requestDts: [],
        workDeptCd: '',
        reqDeptCd: '',
        woWorkReqStepCd: null,
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            fix: true,
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            fix: true,
            name: 'woWorkPlanStepCd',
            field: 'woWorkPlanStepCd',
            label: '진행상태',
            align: 'center',
            type: 'custom',
            style: 'width:100px',
          },
          {
            fix: true,
            name: 'workPlanName',
            field: 'workPlanName',
            label: '정비오더명',
            align: 'left',
            sortable: true,
            style: 'width:300px',
            type: 'link'
          },
          // {
          //   name: 'funcLocationName',
          //   field: 'funcLocationName',
          //   label: '위치',
          //   align: 'center',
          //   style: 'width:150px',
          //   sortable: true,
          // },
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            label: '대표설비Tag',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'workDeptName',
            field: 'workDeptName',
            label: '정비오더 발행부서',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'woWorkTypeName',
            field: 'woWorkTypeName',
            label: '작업유형',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'planDtsStr',
            field: 'planDtsStr',
            label: '작업계획기간',
            align: 'center',
            style: 'width:160px',
            sortable: true,
          },
          {
            name: 'resultDtsStr',
            field: 'resultDtsStr',
            label: '실제작업기간',
            align: 'center',
            style: 'width:160px',
            sortable: true,
          },
          {
            name: 'reqDeptName',
            field: 'reqDeptName',
            label: '정비요청 부서',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'pmMstId',
            field: 'pmMstId',
            label: 'PM 번호',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          // {
          //   name: 'woRequestDt',
          //   field: 'woRequestDt',
          //   label: '요청일',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true,
          // },
          // {
          //   name: 'copywork',
          //   field: 'copywork',
          //   label: '계획복사',
          //   align: 'center',
          //   style: 'width:80px',
          //   type: 'custom',
          //   sortable: true,
          // },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      copyUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.searchParam.plantCd = this.popupParam.plantCd;
      this.listUrl = selectConfig.wod.workorder.plan.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    getColors(_code) {
      var returnColor = '';
      switch(_code) {
        case 'WWPSC00000': // 정비요청
          returnColor = 'grey-6';
          break;
        case 'WWPSC00001': // 작업계획중
          returnColor = 'orange';
          break;
        case 'WWPSC00002': // 작업계획 승인중
          returnColor = 'blue';
          break;
        case 'WWPSC00004': // 작업결과 승인중
          returnColor = 'yellow';
          break;
        case 'WWPSC00003': // 작업진행중
          returnColor = 'deep-purple-6';
          break;
        case 'WWPSC00009': // 작업완료
          returnColor = 'green';
          break;
        case 'WWPSC99999': // 작업취소
        case 'WWPSC99998': // 작업계획취소
          returnColor = 'red-6';
          break;
      }

      return returnColor;
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '정비오더의 복사할 대상을 선택하세요.', // 정비작업표준을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '정비오더 상세';
      this.popupOptions.param = {
        workPlanId: row ? row.workPlanId : '',
        plantCd: row ? row.plantCd : '',
        woWorkPlanStepCd: row ? row.woWorkPlanStepCd : 'WWPSC00001',
        woRequestId: row ? row.woRequestId : '',
        copyFlag: row ? row.copyFlag : 'N',
        firstCopy: row ? (row.firstCopy ? row.firstCopy : false) : false,
      };
      this.popupOptions.target = () => import(`${'@/pages/wod/plan/workOrderPlanDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
